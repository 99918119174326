<template>
  <router-view/>
</template>

<style>
@import './assets/css/style.css';
</style>
<script>

export default {
  name: 'App',
}
</script>
<template>
  <footer>
    <h5>Mes réseaux sociaux</h5>
    <ul>
      <li><a href="https://twitter.com/escalonacharly_"><i class="fa-brands fa-twitter"></i></a> </li>
      <li><a href="https://github.com/L4znet"><i class="fa-brands fa-github"></i></a></li>
    </ul>
    <small>© Charly Escalona 2023</small>
  </footer>
</template>

<script>

export default {
  name: 'Footer',
}
</script>

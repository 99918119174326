<template>
  <nav id="navbar">
    <div class="logo">
      <div class="head">
        <div class="face">
          <div class='container'>
            <div class='eyes'></div>
            <div class='eyes'></div>
          </div>
        </div>
      </div>
    </div>
    <img src="../assets/logo.svg" alt="" class="logo_img">
    <transition appear enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutUp">
      <ul v-if="show" class="menu_mobile">
        <li class="close_btn" @click="changeClass">  <i class="fa-solid fa-times"></i></li>
        <li><a v-smooth-scroll href="/">Home</a></li>
        <li><a v-smooth-scroll href="#about">À propos</a></li>
        <li><a v-smooth-scroll href="#skills">Mes compétences</a></li>
        <li><a v-smooth-scroll href="#works">Mes réalisations</a></li>
      </ul>
    </transition>
    <ul class="menu_desktop">
      <li><a v-smooth-scroll href="/">Home</a></li>
      <li><a v-smooth-scroll href="#about">À propos</a></li>
      <li><a v-smooth-scroll href="#skills">Mes compétences</a></li>
      <li><a v-smooth-scroll href="#works">Mes réalisations</a></li>
    </ul>
    <div class="burger_btn" @click="changeClass">
      <i class="fa-solid fa-bars"></i>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'HeaderNavbar',
  data(){
    return {
      show:false,
    }
  },
  methods:{
    changeClass(){
      this.show = !this.show;
    }
  },

}
</script>

<script setup>
document.querySelector("body").addEventListener("mousemove", (e) => {
  const eyes = document.querySelectorAll(".eyes");


  eyes.forEach(function (eye) {
    let x = eye.getBoundingClientRect().left + eye.clientWidth / 2;
    let y = eye.getBoundingClientRect().top + eye.clientHeight / 2;

    let radian = Math.atan2(event.pageX - x, event.pageY - y);
    let rotate = radian * (180 / Math.PI) * -1 + 180;

    eye.style.transform = "rotate(" + rotate + "deg)";
  });
});

</script>

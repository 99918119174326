import {createApp, h, provide} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Particles from "vue3-particles";
import {PreprPlugin} from "@preprio/vuejs-sdk";
import VueSmoothScroll from 'v-smooth-scroll'
import {MotionPlugin} from "@vueuse/motion";

createApp(App).use(store).use(router).use(VueSmoothScroll, {
    duration: 100,
    updateHistory: false
}).use(Particles).use(PreprPlugin, {
    token: "5df882486737f1e3103698572b5f8efe9fa96d36c181779a569ce0295ab71bbd", // You can find one in your Prepr environment
    baseUrl: "https://graphql.prepr.io/graphql",
}).use(MotionPlugin).mount('#app')


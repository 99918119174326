<template>
  <section class="home">
    <header id="header">
      <div id="overlay"></div>
      <section class="header" id="overlay_bubble">
        <section class="header_content">
          <HeaderNavbar/>
          <h1>Charly <span>Escalona</span></h1>
          <h2>Développeur Javascript</h2>
        </section>
        <svg viewBox="0 0 1440 108" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M-5.73296e-06 65.5774L30.0208 72.6478C60.0417 79.7182 120.083 93.8591 180.125 98.5727C240.167 103.286 300.208 98.5727 360.25 77.3615C420.292 56.1503 480.333 18.4414 540.375 13.7278C600.417 9.01423 660.458 37.2959 720.5 51.4367C780.542 65.5775 840.583 65.5775 900.625 51.4367C960.667 37.2959 1020.71 9.01426 1080.75 1.94386C1140.79 -5.12654 1200.83 9.01428 1260.87 13.7279C1320.92 18.4415 1380.96 13.7279 1410.98 11.3711L1441 9.0143L1441 108L1410.98 108C1380.96 108 1320.92 108 1260.87 108C1200.83 108 1140.79 108 1080.75 108C1020.71 108 960.667 108 900.625 108C840.583 108 780.542 108 720.5 108C660.458 108 600.417 108 540.375 108C480.333 108 420.292 108 360.25 108C300.208 108 240.167 108 180.125 108C120.083 108 60.0417 108 30.0208 108L-9.44165e-06 108L-5.73296e-06 65.5774Z" fill="white"/>
        </svg>
      </section>
    </header>
    <section class="content" >
      <section class="about"  id="about">
      <div class="title">
        <h3>à propos</h3>
        <h4>Qui suis-je ?</h4>
      </div>
      <div class="row">
        <div class="col">
          <div class="aboutme">
            <p>Je m'appelle <strong>Charly Escalona, j'ai 25 ans</strong> et <strong>je suis Développeur Fullstack Web & Mobile</strong>, j'ai commencé le développement web <strong>assez jeune, (aux alentours de mes 13 ans).</strong><br>
              J'ai commencé le développement web en voulant créer un site avec ma mère créé avec un CMS (e-monsite), mais il a rapidement fallu passer à l'HTML, <strong>c'est à ce moment que débuta mon apprentissage.</strong></p>
            <p>Je suis actuellement étudiant en dernière année de Mastère en <strong>Développement Logiciel Mobile IoT</strong>.</p>
            <p>Quand je ne code pas, je suis probablement à <strong>la salle de sport,</strong> ou devant une série, j'ai particulièrement apprécié <strong>Silicon Valley</strong>, Mr Robot ou encore le documentaire sur la création de Spotify (The Playlist).</p>
            <p>Je suis également un grand fan du groupe <strong>Dropkick Murphies.</strong></p>
            <p>Si on revient au code, <strong>j’adore utiliser Vercel</strong> pour héberger mes projets, c’est là que sont tous mes projets en javascript.</p>
            <p>Au delà du dev, je m’intéresse à plein d’autres sujets, le Transhumanisme, les voitures autonomes, les IA, la biotechnologie et l’IoT pour citer quelques exemples.</p>
            <p>Je suis également un grand fan des univers <strong>Steampunk</strong> et <strong>Cyberpunk</strong>.</p>

            <ul>
              <li><a href="https://charly-e.medium.com/quest-ce-qui-m-a-pouss%C3%A9-%C3%A0-coder-ff09aafa0779" class="button normal">En savoir plus</a></li>
              <li><a :href="'data:application/pdf;base64,' + base64" download="cv_charly_escalona.pdf" class="button normal">Télécharger mon CV</a></li>
              <li><a :href="'data:application/pdf;base64,' + base64imprimable" download="cv_charly_escalona_imprimable.pdf" class="button normal">CV imprimable</a></li>
            </ul>

          </div>
        </div>
      </div>
      </section>
      <section class="skills" id="skills">
        <div class="title">
          <h3>Mes compétences</h3>
          <h4>Ce que j'utilise fréquemment</h4>
        </div>
        <div class="row">
          <ul class="navigation_items">
            <li v-bind:class="[category.slug === categoryShown ? 'focused' : 'normal']" v-for="(category, index) in categories" :key="index">
              <a @click.prevent="change(category.slug, 'category')">
                <i v-if="category.icon !== 'fa-windows'" :class="'fa-solid ' + category.icon"></i>
                <i v-else :class="'fa-brands ' + category.icon"></i>
                <span> {{ category.name }} </span>
              </a>
            </li>
          </ul>
        </div>
        <section class="skills_items">
          <template v-if="skills.filter(skill => skill.category === categoryShown).length > 0">

            <template v-for="(skill, index) in skills" :key="index">
              <article  v-if="skill.category === categoryShown">
                <img :src="skill.icon[0].url" :alt="'Compétences ' + skill.title">
                <h4>{{ skill.title }}</h4>
              </article>
            </template>
          </template>

          <template v-else>
            <h4 class="nothing_to_show">Aucune compétences à afficher pour le moment...</h4>
          </template>
        </section>
      </section>
      <section class="works" id="works">
        <div class="title">
          <h3>Mes réalisations</h3>
          <h4>Ce que j'ai fais</h4>
        </div>
        <div class="row">
          <ul class="navigation_items">
            <li v-bind:class="[worktype.type === workTypeShown ? 'focused' : 'normal']" v-for="(worktype, index) in workstypes" :key="index">
              <a @click.prevent="change(worktype.type, 'worktype')">
                <i :class="'fa-solid ' + worktype.icon"></i>
                <span> {{ worktype.name }} </span>
              </a>
            </li>
          </ul>
        </div>
        <section class="works_items">
          <template v-if="works.filter(work => work.category === workTypeShown).length > 0 || workTypeShown === 'all'">
            <template v-for="(work, index) in works" :key="index">
              <template v-if="workTypeShown === 'all' || work.category === workTypeShown">
                <article >
                  <div class="first">
                    <h4>{{work.title}}</h4>
                    <h5><span v-for="(language, indexTag) in work.languages" :key="indexTag">{{language.body}}{{work.languages.length === indexTag + 1 ? '' : ', '}} </span></h5>
                  </div>
                  <div class="thumbnail">
                    <img :src="work.thumbnail[0].url">
                    <div class="thumbnail_body">
                      <p> {{work.description}} </p>
                      <ul>
                        <li v-if="work.demo_url"><a :href="work.demo_url"><i class="fa-solid fa-link"></i></a></li>
                        <li v-if="work.github_url"><a :href="work.github_url"><i class="fa-brands fa-github"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </article>
              </template>
            </template>
          </template>
          <template v-else>
            <h4 class="nothing_to_show">Aucune réalisations à afficher pour le moment...</h4>
          </template>
        </section>
      </section>
      <section class="contactme" id="contactme">
        <div class="title">
          <h3>Contact</h3>
          <h4>Des questions ?</h4>
        </div>
        <div class="content">
          <ul class="contact_option">
            <li>
              <div class="thumbnail">
                <i class="fa-solid fa-envelope"></i>
              </div>
              <div class="content">
                <h4>E-mail</h4>
                <span>hello@charly-e.com</span>
              </div>
            </li>
            <li>
              <div class="thumbnail">
                <i class="fa-solid fa-phone"></i>
              </div>
              <div class="content">
                <h4>Téléphone</h4>
                <span>0664720207</span>
              </div>
            </li>
          </ul>
          <form action=""  v-if="!displaySuccess">
            <input type="text" v-model="displayName" placeholder="Votre nom / prénom">
            <input type="email" v-model="email" placeholder="E-mail">
            <textarea v-model="message" placeholder="Message"></textarea>
            <div class="button_line">
              <button v-if="!loading" class="submit_button"  @click.prevent="sendEmail">Envoyer un message</button>
              <button v-else class="disabled_button">  <i class="fas fa-circle-notch fa-spin"></i> Envoi en cours..</button>
              <small>Promis, pas de spam, moi aussi je déteste ça.</small>
              <small>Je considère que votre vie privée est aussi importante que la mienne et me conforme au RGPD. Mon service de récupération de messages peut stocker vos données. Sur votre demande, je demanderai à ce service de supprimer vos données.
                De mon côté, je ne stocke aucune de vos données.
                Vous pouvez faire ce type de demande en m'envoyant un message à l'adresse e-mail suivante : hello@charly-e.com  ou via le formulaire ci-dessus.</small>
            </div>
          </form>
          <div v-else class="message success_message">
            <div class="icon_box">
              <i class="fa-solid fa-check"></i>
            </div>
            Votre message a bien été envoyé !
          </div>
        </div>

      </section>
    </section>
    <Footer/>
  </section>
</template>

<script>

import HeaderNavbar from '@/components/HeaderNavbar';
import "particles.js";
import skillstest from "../assets/skills.json"
import categories from "../assets/categories.json"
import workstypes from "../assets/work_types.json"
import works from "../assets/works.json"
import Footer from "@/components/Footer";
import {saveAs} from 'file-saver';
import axios from 'axios';
import base64 from "@/assets/base64.json";
import base64imprimable from "@/assets/base64imprimable.json";
import emailjs from '@emailjs/browser';


export default {
  name: 'HomeView',
  components: {
    HeaderNavbar,Footer
  },
  data(){
    return {
      categories:[],
      workstypes:[],
      works:[],
      skills:[],
      links:[],
      categoryShown:'webmobile',
      workTypeShown:'all',
      base64:base64[0].base64,
      base64imprimable:base64imprimable[0].base64,
      options: {
        scrollBar: false
      },
      email:"",
      message:"",
      displayName:"",
      displaySuccess:false,
      errors:[],
      loading:false,
    }
  },
  methods: {
    initParticles() {
      window.particlesJS("overlay", {
        particles: {
          number: {
            value: 10,
            density: {
              enable: true,
              value_area: 710,
            },
          },
          color: {
            value: "#5BFDA5",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#5BFDA5",
            },
          },
          opacity: {
            value: 1,
            random: true,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 70,
            random: false,
            anim: {
              enable: true,
              speed: 5,
              size_min: 100,
              sync: true,
            },
          },
          line_linked: {
            enable: false,
          },
          move: {
            enable: true,
            speed: 20,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: true,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "window",
          events: {
            onhover: {
              enable: false,
              mode: "grab",
            },
            onclick: {
              enable: false,
              mode: "remove",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    },
    loadCategories(){
      this.categories = categories
    },
    loadworktypes(){
      this.workstypes = workstypes
    },
    change(current, whatToChange){
      if(whatToChange === "category"){
        this.categoryShown = current
      } else if(whatToChange === "worktype"){
        this.workTypeShown = current
      }
    },
    sendEmail(){

      if(this.email !== "" && this.message !== "" && this.displayName !== ""){
        this.loading = true;
        emailjs.send("service_cma3ayv","template_yyzycib",{
          from_name: this.displayName,
          reply_to: this.email,
          message: this.message,
        }, "t4fEJNW1y6TEDoJg_").then((result) => {
          if(result.status === 200){
            this.loading = false
            this.displaySuccess = true;
          }
        });
      } else {
        this.errors[0] = "Vous devez remplir les champs"
      }
    }
  },
  async mounted() {
    this.initParticles();
    this.loadCategories();
    this.loadworktypes();
    const response = await this.$prepr
        .graphqlQuery(
            `{
               works : Works {
                   items {
                     _id
                     title
                     languages {
                      body
                     }
                     description
                     thumbnail {
                      _id
                      url
                     }
                     github_url
                     demo_url
                     category
                   }
               },
               skills : Skills(limit : 80) {
                   items {
                      _id
                      title
                      icon {
                        _id
                        url
                      }
                      category
                   }
               },
               links : Links {
                  items {
                    github_url
                    instagram_url
                    twitter_url
                    cv_url {
                      url
                    }
                  }
               }
            }`
        )
        .fetch();

    this.works = response.data.works.items
    this.skills = response.data.skills.items
    this.links = response.data.links.items
  },
}
</script>
